import styled from 'styled-components';

export const Wrapper = styled.div`
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100vh;
  width: 100vw;
  position: fixed;

  // blur background
  background: rgba(0, 0, 0, 0.7);
  backdrop-filter: blur(1px);
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 100;
`;
export const Modal = styled.div`
  position: relative;
  width: 40%;
  height: 15rem;
  background: radial-gradient(
    circle,
    rgba(25, 69, 71, 0.8) 7%,
    rgba(21, 32, 43, 1) 74%
  );
  border-radius: 10px;

  .close {
    position: absolute;
    top: 1rem;
    right: 1rem;
    color: #f1f1f1;
    font-size: 2rem;
    cursor: pointer;
  }
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex-direction: column;

  p {
    margin: 0;
    color: #f1f1f1;
  }

  @media screen and (max-width: 900px) {
    width: 300px;
    height: 280px;
    padding: 1rem 0.5rem;

    .close {
      top: 0.4rem;
      right: 0.4rem;
    }
  }
`;

export const Title = styled.p`
  text-align: center;
  position: unset;
  font-size: 30px;

  @media screen and (max-width: 900px) {
    font-size: 25px;
    margin: 0;
    padding: 0;
    line-height: 1.1;
  }
`;
export const Description = styled.p`
  font-size: 1em;
  text-align: center;
  position: unset;
`;
export const GetStarted = styled.button`
  position: unset;
  width: 200px;
  height: 40px;
  border-radius: 10px;
  font-size: 20px;
  font-weight: 600;
  cursor: pointer;
  background: #f1f1f1;
  color: #000;
`;
