import React from 'react';
import PropTypes from 'prop-types';

import { Wrapper, Modal, Title, Description } from './preview.styles';

import { AiOutlineCloseCircle } from 'react-icons/ai';
import { useSession } from 'next-auth/react';
import Link from 'next/link';

export default function PreviewModal({ close }) {
  const clickOut = (e) => {
    if (e.target === e.currentTarget) {
      close();
    }
  };
  const { status } = useSession();
  return (
    <Wrapper onClick={(e) => clickOut(e)}>
      <Modal>
        <AiOutlineCloseCircle className="icon close" onClick={() => close()} />
        <Title>You are in preview mode 🤔</Title>
        <Description>
          Create an account or login to get connect with friends and colleagues
          already using Karent!
        </Description>
        <Link
          href={status === 'authenticated' ? '/' : '/welcome'}
          className="mr-4 flex items-center gap-4 rounded-3xl bg-white px-6 py-2 text-black hover:text-black"
        >
          {status === 'authenticated' ? 'Ready' : 'Continue with google'}
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="#f40101"
            className="h-6 w-6"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M15.59 14.37a6 6 0 01-5.84 7.38v-4.8m5.84-2.58a14.98 14.98 0 006.16-12.12A14.98 14.98 0 009.631 8.41m5.96 5.96a14.926 14.926 0 01-5.841 2.58m-.119-8.54a6 6 0 00-7.381 5.84h4.8m2.581-5.84a14.927 14.927 0 00-2.58 5.84m2.699 2.7c-.103.021-.207.041-.311.06a15.09 15.09 0 01-2.448-2.448 14.9 14.9 0 01.06-.312m-2.24 2.39a4.493 4.493 0 00-1.757 4.306 4.493 4.493 0 004.306-1.758M16.5 9a1.5 1.5 0 11-3 0 1.5 1.5 0 013 0z"
            />
          </svg>
        </Link>
      </Modal>
    </Wrapper>
  );
}

PreviewModal.propTypes = {
  close: PropTypes.func,
};
