export const tags = [
  {
    name: 'conservation',
    id: '63e494773b02d762a991783a',
    checked: false,
    color: 'green',
  },
  {
    name: 'entrepreneurship',
    id: '63e494bd3b02d762a991783b',
    checked: false,
    color: 'green',
  },
  {
    name: 'writing',
    id: '63e494db3b02d762a991783c',
    checked: false,
    color: 'green',
  },
  {
    name: 'tech',
    id: '63e494ee3b02d762a991783d',
    checked: false,
    color: 'green',
  },
  {
    name: 'careers',
    id: '63e495003b02d762a991783e',
    checked: false,
    color: 'green',
  },
  {
    name: 'social',
    id: '63e495113b02d762a991783f',
    checked: false,
    color: 'green',
  },
  {
    name: 'music',
    id: '63e495203b02d762a9917840',
    checked: false,
    color: 'green',
  },
  {
    name: 'travel',
    id: '63e495303b02d762a9917841',
    checked: false,
    color: 'green',
  },
  {
    name: 'food',
    id: '63e495413b02d762a9917842',
    checked: false,
    color: 'green',
  },
  {
    name: 'art',
    id: '63e495513b02d762a9917843',
    checked: false,
    color: 'green',
  },
];
export const TagsMock = [
  { name: 'conservation', id: '63e494773b02d762a991783a', checked: false },
  { name: 'entrepreneurship', id: '63e494bd3b02d762a991783b', checked: false },
  { name: 'writing', id: '63e494db3b02d762a991783c', checked: false },
  { name: 'tech', id: '63e494ee3b02d762a991783d', checked: false },
  { name: 'careers', id: '63e495003b02d762a991783e', checked: false },
  { name: 'social', id: '63e495113b02d762a991783f', checked: false },
  { name: 'music', id: '63e495203b02d762a9917840', checked: false },
  { name: 'travel', id: '63e495303b02d762a9917841', checked: false },
  { name: 'food', id: '63e495413b02d762a9917842', checked: false },
  { name: 'art', id: '63e495513b02d762a9917843', checked: false },
];
